import React, {useEffect, useState} from 'react';
import { Paper, FormControlLabel, RadioGroup, Radio, Button, Typography, Divider, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { postVerificaitons, putComparingData } from '../lib/api/ekyc';
import Loading from "../components/Loading";
import { getProfile, updateProfileSupportingdocuments } from '../lib/api/profile';
import ImageButton from '../components/ImageButton';

export const Ekyc = () =>{
  const [selectedRadioBtnValue, setSelectedRadioBtnValue] = useState("ekyc1");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEkyc, setSelectedEkyc] = useState('ekyc1');
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const initial = query.get('initial');

  const handleGetProfile = async() =>{
    try{
      const res = await getProfile();
      if(res.status === 200){
        const rec = res.data.record;
        const identification = res.data.record.identification;
        const recentIdentification = res.data.record.recentIdentification?.tdStateC;
        const user = res.data.record;
        // 連続否認の場合もdisable
        const thirdFlg = res.data.record.threeconsecutivedenials;
        if(thirdFlg || identification == '本人確認済み' || identification == '確認中' || identification == '書類提出済み')
        {
          navigate('/profile');
        }
        if((identification == null || identification == '-' || identification == '否認') && (recentIdentification == 'document_submitted' || recentIdentification == 'in_progress')){
          navigate('/profile');
        }
        if(!user.raPostalcode || !user.raState || !user.raCity || !user.raStreet){
          navigate('/profile');
        }
      }
    }catch(e){
      console.log(e);
      navigate('/profile');
    }
  }

  useEffect(() =>{
    handleGetProfile();
  }, []);

  const trustdockModal = (public_id, plan_ids, update_type, is_development) =>{
    const trustdock = new Trustdock({
      publicId: public_id,
      plans: plan_ids,
      openerSelector: '.opener'
    });
    const autoOpen = () => {
      if (trustdock.isLoaded) {
        setIsLoading(false);
        trustdock.open()
      } else {
        trustdock.on('loaded', () => {
          setIsLoading(false);
          trustdock.open()
        })
      }
    }
    const documentsubmittedAction = async() => {
      const res = await putComparingData(public_id, is_development, update_type == 1);
      if (update_type <= 1){
        await updateProfileSupportingdocuments('不要');
      }else{
        await updateProfileSupportingdocuments('未確認');
      }
    }
    const completedAction = () => {
      if(update_type == 2){
        if(initial){
          navigate('/ekyc/success?initial=true');
        }else{
          navigate('/ekyc/success');
        }
      }else{
        if(initial){
          navigate('/factoring/app/new');
        }else{
          navigate('/profile');
        }
      }
    }
    const canceledAction = () => {
    }
    //// イベント受け取った場合の処理
    // 身元確認書類の提出が完了した場合の処理を記述
    // 例えば、提出後モーダルを閉じないユーザーに対して強制的に次のページへ遷移する等
    trustdock.on('documentsubmitted', documentsubmittedAction)
    // 身元確認書類の提出が完了しモーダルを閉じた場合の処理を記述
    trustdock.on('completed',completedAction )
    // 身元確認書類の提出をせずモーダルを閉じた場合の処理を記述
    trustdock.on('canceled', canceledAction)
    autoOpen()
  }

  useEffect(() =>{
    const scripts = document.getElementsByTagName('script');
    for(let script of scripts){
      if(script.src == 'https://helper.trustdock.io/v2/verification_helper.js') script.remove();
      if(script.src == 'https://helper.test.trustdock.io/v2/verification_helper.js') script.remove();
    }
    if(location.host == 'www.portal.betrading.jp'){
      const head = document.getElementsByTagName('head')[0] as HTMLElement;
      const scriptUrl = document.createElement('script');
      scriptUrl.type = 'text/javascript';
      scriptUrl.src = 'https://helper.trustdock.io/v2/verification_helper.js';
      head.appendChild(scriptUrl);
    }else{
      const head = document.getElementsByTagName('head')[0] as HTMLElement;
      const scriptUrl = document.createElement('script');
      scriptUrl.type = 'text/javascript';
      scriptUrl.src = 'https://helper.test.trustdock.io/v2/verification_helper.js';
      head.appendChild(scriptUrl);
    }
  }, [selectedRadioBtnValue])

  const onclickSubmit = async () =>{
    if(isLoading) return;
    setIsLoading(true);
    const is_development = selectedRadioBtnValue == 'ekyc1' || selectedRadioBtnValue == 'ekyc2' || selectedRadioBtnValue == 'ekyc3' ? false : true;
    const resp = await postVerificaitons(is_development);
    if(resp.status == 202){
      if(selectedRadioBtnValue == 'ekyc1'){
        if(location.host == 'www.portal.betrading.jp'){
          trustdockModal(resp.data.data.publicId, [{id: '423930b3-0d71-4ecf-8ef3-af1cc797be0d'}, {id: '32d8d48a-0059-440e-bea6-9a23b722933c', document_source_plan_id: '423930b3-0d71-4ecf-8ef3-af1cc797be0d'}], 0, is_development);
        }else{
          trustdockModal(resp.data.data.publicId, [{id: 'b57ed80b-4e3e-44c3-96ce-8fde407974e8'}, {id: '944d393b-da9e-4f56-8f17-e5d37198bcdd', document_source_plan_id: 'b57ed80b-4e3e-44c3-96ce-8fde407974e8'}], 0, is_development);
        }
      }else if(selectedRadioBtnValue == 'ekyc2'){
        if(location.host == 'www.portal.betrading.jp'){
          trustdockModal(resp.data.data.publicId, [{id: '7f09549d-3474-4ee6-b55b-da568c615dde'}, {id: '32d8d48a-0059-440e-bea6-9a23b722933c', document_source_plan_id: '7f09549d-3474-4ee6-b55b-da568c615dde'}, {id: '3b87ea82-6ff6-4a8b-8b59-d70e6fba24c8'}], 1, is_development);
        }else{
          trustdockModal(resp.data.data.publicId, [{id: 'b9e69f1f-d630-4850-98d7-c1594c8ffed9'}, {id: '944d393b-da9e-4f56-8f17-e5d37198bcdd', document_source_plan_id: 'b9e69f1f-d630-4850-98d7-c1594c8ffed9'}, {id: 'ca06f581-b3f9-4800-b80f-4e7ad707a71e'}], 1, is_development);
        }
      }else if(selectedRadioBtnValue == 'ekyc3'){
        if(location.host == 'www.portal.betrading.jp'){
          trustdockModal(resp.data.data.publicId, [{id: '0ed34cba-db67-4a4f-a55c-59927349f916'}, {id: '32d8d48a-0059-440e-bea6-9a23b722933c', document_source_plan_id: '0ed34cba-db67-4a4f-a55c-59927349f916'}, {id: '3b87ea82-6ff6-4a8b-8b59-d70e6fba24c8'}], 2, is_development);
        }else{
          trustdockModal(resp.data.data.publicId, [{id: '58bb9c08-5a23-4932-9201-1599a6186cff'}, {id: '944d393b-da9e-4f56-8f17-e5d37198bcdd', document_source_plan_id: '58bb9c08-5a23-4932-9201-1599a6186cff'}, {id: 'ca06f581-b3f9-4800-b80f-4e7ad707a71e'}], 2, is_development);
        }
      }
    }
  }

  const onRadioButtonChange = (evt)=>{
    setSelectedRadioBtnValue(evt.target.value);
  }
  return (
    <Box sx={{ width: '100%', borderRadius: 2, pl: {xs: 2}, pr: {xs: 2}, pt: {xs: 1}, pb: {xs: 1} }}>
      <Typography>本人確認書類を選んでください。</Typography>
      <Box sx={{display: {md: 'flex'}}}>
        <ImageButton onClick={(e) => {setSelectedRadioBtnValue('ekyc1')}} imgUrl="/icons/myno.svg" buttonTitle="運転免許証、マイナンバーなど" disabled={selectedRadioBtnValue=="ekyc1"} disableColor='#FEFADC' disableBorder='2px solid #EBBF6C' arrowEnable={false}/>
        <ImageButton onClick={(e) => {setSelectedRadioBtnValue('ekyc2')}} imgUrl="/icons/passport.svg" buttonTitle="パスポート" disabled={selectedRadioBtnValue=="ekyc2"} disableColor='#FEFADC' disableBorder='2px solid #EBBF6C' arrowEnable={false}/>
        <ImageButton onClick={(e) => {setSelectedRadioBtnValue('ekyc3')}} imgUrl="/icons/hoken.svg" buttonTitle="健康保険証"  disabled={selectedRadioBtnValue=="ekyc3"} disableColor='#FEFADC' disableBorder='2px solid #EBBF6C' arrowEnable={false}/>
      </Box>
      <Box sx={{mt: 3}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>{onclickSubmit()}}
          sx={{borderRadius: 5, width: {md: '20%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
        >
          身元確認を行う
        </Button>
      </Box>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
    </Box>
  );
};