import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller} from "react-hook-form";
import { Button, TextField, Grid, InputLabel, Box, Container, Link as MuiLink, FormControl, Typography, RadioGroup, FormControlLabel, Radio  } from '@mui/material';
import Loading from "../../components/Loading";
import Cookies from 'js-cookie';
import { ErrorModal } from "../../components/ErrorModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { weekdayTexts, CustomDatePickerToolbar } from "../../common/common";
import { userid } from "../../lib/api/auth";

const validationRules = {
  lastnamekana:{
    required: 'お名前(セイ)を入力してください'
  },
  firstnamekana:{
    required: 'お名前(メイ)を入力してください'
  },
  mobilephone:{
    required: '携帯番号を入力してください'
  },
  birthdate:{
    required: '誕生日を入力してください'
  }
}

export const ForgetId = () => {
  const { control, handleSubmit, setValue } = useForm({defaultValues: {lastnamekana: '', firstnamekana: '', mobilephone: '', birthdate: null}});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const handleIdSubmit = async (evt) => {
    try{
      evt.birthdate = evt.birthdate.format('YYYY-MM-DD');
      const res = await userid(evt);
      if(res.status === 200){
        navigate('/login/forget_id/success', {state: res.data.email});
      }
    }catch(e){
      if(e.response.status == 406){
        setErrorMessage(
        <>
          <Typography>該当する登録が2件以上あります。</Typography>
          <Typography>お手数ですが<Box component='a' sx={{wordBreak: 'break-all'}} className='link' target='_blank' href='https://betrading.jp/company/contact/'>こちら</Box>からお問い合わせください。</Typography>
        </>);
      }else{
        setErrorMessage(<>{e.response.data.messages.map(message =>(<Typography>{message}</Typography>))}</>);
      }
      handleOpenErrorModal();
    }
    return;
  };

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  return (
    <Container
      sx={{
        width: {md: '80%', xs: '95%'},
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        border: 'solid',
        borderColor: '#E1DED0',
        pt: 5,
        pb: 5
      }}
    >
      <form id='sign_up_form' onSubmit={handleSubmit(handleIdSubmit)}>
        <Grid container>
          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
            <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
              お名前
            </InputLabel>
            <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
              ※全角カナ
            </InputLabel>
          </Grid>
          <Controller
            control={control}
            name='lastnamekana'
            rules={validationRules['lastnamekana']}
            render={({ field, fieldState }) => (
              <Grid item md={4.5} sm={6} xs={6}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 0, md: 2}}}
                  margin="normal"
                  color="secondary"
                  placeholder="セイ"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              </Grid>
            )}
          />
          <Controller
            control={control}
            name='firstnamekana'
            rules={validationRules['firstnamekana']}
            render={({ field, fieldState }) => (
              <Grid item md={4.5} sm={6} xs={6}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 0, md: 2}}}
                  margin="normal"
                  color="secondary"
                  placeholder="メイ"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              </Grid>
            )}
          />
        </Grid>
        <Controller
          control={control}
          name='mobilephone'
          rules={validationRules['mobilephone']}
          render={({ field, fieldState }) => (
            <Box>
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    携帯番号
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    ※半角数字
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 0, md: 2}}}
                    margin="normal"
                    placeholder="00000000000"
                    color="secondary"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        />
        <Controller
          control={control}
          rules={validationRules['birthdate']}
          name='birthdate'
          render={({ field, fieldState }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12} sx={{m: 'auto'}}>
                <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  生年月日
                </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: "YYYY年 MM月", monthShort: "MM月" }}>
                    <DatePicker
                      {...field}
                      format={'YYYY/MM/DD'}
                      onChange={(newValue) =>{if(newValue) setValue('birthdate', newValue)}}
                      dayOfWeekFormatter={(weekday) => `${weekdayTexts[weekday]}`}
                      slotProps={{textField: {helperText: fieldState?.error?.message, error: Boolean(fieldState?.error)}}}
                      slots={{
                        toolbar: CustomDatePickerToolbar
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          )}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          送信
        </Button>
      </form>
      { Cookies.get('isSignedIn') && Cookies.get('isSignedIn') == 'true' ?
      <></>
      :
      <MuiLink
        component={Link}
        to="/login"
        underline="hover"
      >すでに登録済みの方はこちら</MuiLink>
      }
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Container>
  );
};