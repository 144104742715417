import dayjs, {Dayjs} from 'dayjs';

export interface Input{
  id: string;
  kind: string;
  companyName: string;
  postalcode: string;
  state: string;
  city: string;
  street: string;
  other: string;
  houjinNo: string;
  hpAddress: string;
  phone: string;
  fixDate: string;
  paymentDate: string;
  manager: string;
}

export interface ClientInput{
  kind: string;
  companyName: string;
  postalcode: string;
  state: string;
  city: string;
  street: string;
  houjinNo: string;
}

export interface Index {
  detailButton: string;
  companyName: string;
  address: string;
  fixDate: string;
  paymentDate: string;
  createdAt: string;
}

interface Column {
  id: 'detailButton' | 'companyName' | 'address' | 'createdAt' | 'fixDate' | 'paymentDate';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
  type?: string;
  secondRow: string;
  secondLabel: string;
}

export const columns: Column[] = [
  { id: 'detailButton', label: '', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'companyName', label: '売掛先名', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'address', label: '売掛先住所', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'fixDate', label: '締日', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'paymentDate', label: '支払日', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'createdAt', label: '登録日', secondLabel: '', minWidth: 170, secondRow: '', align: 'center', type: 'date'},
]

export const mobileColumns: Column[] = [
  { id: 'companyName', label: '売掛先名', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'address', label: '売掛先住所', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
  { id: 'createdAt', label: '登録日', secondLabel: '', minWidth: 170, secondRow: '', align: 'center', type: 'date'},
  { id: 'detailButton', label: '', secondLabel: '', minWidth: 170, secondRow: '', align: 'center'},
]

export const defaultValues: Input ={
  id: '',
  kind: '法人',
  companyName: '',
  postalcode: '',
  state: '',
  city: '',
  street: '',
  other: '',
  houjinNo: '',
  hpAddress: '',
  phone: '',
  fixDate: '',
  paymentDate: '',
  manager: '',
}

export const clientDefaultValues: ClientInput={
  kind: '法人',
  companyName: '',
  postalcode: '',
  state: '',
  city: '',
  street: '',
  houjinNo: ''
}

export function createIndexRow(
  detailButton: string,
  companyName: string,
  address: string,
  fixDate: string,
  paymentDate: string,
  createdAt: string
): Index{
  return {detailButton, companyName, address, fixDate, paymentDate, createdAt}
}


interface SelectOption{
  label: string;
  value: string;
}

interface Field{
  id: 'id' | 'kind' | 'companyName' | 'postalcode' | 'state' | 'city' | 'street' | 'other' | 'houjinNo' | 'hpAddress' | 'phone' | 'fixDate' | 'paymentDate' | 'manager';
  placeholder: string;
  type: string;
  html_id: string;
  options: SelectOption[];
  disable?: boolean;
}

interface FormInfo {
  id: 'horizontal' | 'vertical' ;
  label: string;
  required: boolean;
  fields: Field[];
}

interface AcGroup {
  group_class: string;
  color: string;
  title: string;
  message: string;
  warning?: string;
  forms: FormInfo[]
}

interface ClientInfo {
  id: 'kind' | 'companyName' | 'postalcode' | 'state' | 'city' | 'street' | 'houjinNo';
  label: string;
  type: string;
  html_id: string;
  options: SelectOption[];
  required: boolean;
}

export const AccountsReceivableFormInfo: AcGroup[] = [
  {
    group_class: 'company_info',
    color: '#000000',
    title: '売掛先情報',
    message: 'お取引のある売掛先情報をご入力ください',
    forms: [
      {id: 'horizontal', label: '事業形態', required: true, fields: [
        {id: 'kind', placeholder: '', type: 'select', options: [{label: '法人', value: '法人'}, {label: '個人事業主', value: '個人事業主'}], html_id: 'kind_id'},
      ]},
      {id: 'horizontal', label: '売掛先名', required: true, fields: [
        {id: 'companyName', placeholder: '会社名', type: 'string', options: [], html_id: ''},
      ]},
      {id: 'vertical', label: '売掛先住所', required: true, fields: [
        {id: 'postalcode', placeholder: '郵便番号', type: 'string', options: [], html_id: ''},
        {id: 'state', placeholder: '都道府県', type: 'string', options: [], html_id: ''},
        {id: 'city', placeholder: '市区町村', type: 'string', options: [], html_id: ''},
        {id: 'street', placeholder: '町名・番地', type: 'string', options: [], html_id: ''},
        {id: 'other', placeholder: '建物名・部屋番号など', type: 'string', options: [], html_id: ''}
      ]},
      {id: 'horizontal', label: '法人番号', required: false, fields: [
        {id: 'houjinNo', placeholder: '', type: 'string', options: [], html_id: '', disable: true},
      ]},
      {id: 'horizontal', label: '会社HP', required: false, fields: [
        {id: 'hpAddress', placeholder: '', type: 'string', options: [], html_id: ''},
      ]},
      {id: 'horizontal', label: '電話番号', required: true, fields: [
        {id: 'phone', placeholder: '', type: 'string', options:[], html_id: ''},
      ]},
      {id: 'horizontal', label: '担当者名', required: true, fields: [
        {id: 'manager', placeholder: '', type: 'string', options: [], html_id: ''}
      ]},
      {id: 'vertical', label: '支払条件', required: true, fields: [
        {id: 'fixDate', placeholder: '請求締日(例：月末、15日)', type: 'string', options: [], html_id: ''},
        {id: 'paymentDate', placeholder: '支払日(例：翌月15日、翌々月末)', type: 'string', options: [], html_id: ''},
      ]},
    ]
  }
];

export const ClientFormInfo: ClientInfo[] = [
  {id: 'kind', label: '事業形態', type: 'select', options: [{label: '法人', value: '法人'}, {label: '個人事業主', value: '個人事業主'}], html_id: 'kind_id', required: true},
  {id: 'companyName', label: '会社名', type: 'string', options: [], html_id: '', required: true},
  {id: 'postalcode', label: '住所(郵便番号)', type: 'string', options: [], html_id: '', required: true},
  {id: 'state', label: '住所(都道府県)', type: 'string', options: [], html_id: '', required: true},
  {id: 'city', label: '住所(市区町村)', type: 'string', options: [], html_id: '', required: true},
  {id: 'street', label: '住所(町名・番地)', type: 'string', options: [], html_id: '', required: true},
  {id: 'houjinNo', label: '法人番号', type: 'hidden', options: [], html_id: '', required: false}
];

export const validationRules = {
  kind:{
    required: '事業形態を入力してください'
  },
  companyName:{
    maxLength: {
      value: 80,
      message: '80文字以下で入力してください'
    },
    required: '売掛先名を入力してください'
  },
  phone:{
    required: '電話番号を入力してください',
    validate: (value, formValues) =>{
      const mobilephonePattern = /^\d{2,5}[-(]?\d{1,4}[-)]?\d{4}$/;
      if(mobilephonePattern.test(value)){
        const nums = value.replace(/[()-]/g, '');
        return nums.length > 9 && nums.length < 12 || '電話番号は10桁以上11桁以内で入力してください'
      }else{
        return '電話番号の形式が合っていません';
      }
    }
  },
  manager:{
    maxLength: {
      value: 30,
      message: '30文字以下で入力してください'
    },
    required: '担当者を入力してください'
  },
  state:{
    maxLength: {
      value: 6,
      message: '6文字以下で入力してください'
    },
    required: '都道府県を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  postalcode: {
    required: '郵便番号を入力してください',
    maxLength: {
      value: 8,
      message: '8文字以下で入力してください'
    },
  },
  city:{
    maxLength: {
      value: 20,
      message: '20文字以下で入力してください'
    },
    required: '市区町村を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  street:{
    maxLength: {
      value: 50,
      message: '50文字以下で入力してください'
    },
    required: '町名番地を入力してください',
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  other:{
    maxLength: {
      value: 30,
      message: '30文字以下で入力してください'
    },
    validate: (value, formValues) =>{
      const textValidPattern = /^[ａ-ｚＡ-Ｚ０-９\p{sc=Han}\p{sc=Hiragana}\p{sc=Katakana}ー－＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ]*$/u;
      return textValidPattern.test(value) || '使用できる文字はひらがな、カタカナ、全角英数、記号(ー＇＆．（）・～／＜＞＋，＂ⅰⅠⅱⅡⅲⅢⅳⅣⅴⅤⅵⅥⅶⅦⅷⅧⅸⅨⅹⅩⅺⅪⅻⅫ)です';
    }
  },
  fixDate:{
    maxLength: {
      value: 30,
      message: '30文字以下で入力してください'
    },
    required: '請求締日を入力してください'
  },
  paymentDate:{
    maxLength: {
      value: 30,
      message: '30文字以下で入力してください'
    },
    required: '支払日を入力してください'
  },
  hpAddress:{
    maxLength: {
      value: 255,
      message: '255文字以下で入力してください'
    }
  }
}

export const clientDefaultRules = {
  kind:{
    required: '事業形態を入力してください'
  },
  companyName:{
    required: '売掛先名を入力してください'
  },
  postalcode:{
    required: '郵便番号を入力してください'
  },
  state:{
    required: '都道府県を入力してください'
  },
  city: {
    required: '市区町村を入力してください'
  },
  street:{
    required: '町名・番地を入力してください'
  }
}
